<!--
   websocket 编码解码 组件
-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<div class="websocket-componet">
				<a
					href="javascript:history.back(-1)"
					rel="noopener noreferrer"
					class="goback"
					:title="$t('lang.gobackBack')"
				>
					<i class="el-icon-back"></i>
					{{ $t('lang.goback') }}
				</a>
				<h3 class="title">{{ h3 }}</h3>
				<el-row :gutter="20">
					<el-col :span="11" class="col">
						<el-row>
							<h3 class="title-h3">
								{{ $t('lang.websocket.configurationStatus') }}:
								{{
									connectState
										? $t('lang.websocket.connectSuccess')
										: $t('lang.websocket.connectClose')
								}}
							</h3>
							<!-- 搜索框部分 -->
							<el-col :span="24" class="col">
								<el-input
									:placeholder="$t('lang.websocket.websocketInput')"
									:class="inputState ? 'warn-active-input' : ''"
									@focus="inputState = false"
									v-model.trim="form.wsAddress"
									:disabled="connectState"
									clearable
								>
									<template slot="prepend"
										><span class="hash-span">
											{{ $t('lang.websocket.websocketURL') }}</span
										></template
									>
									<template slot="append">
										<el-button
											class="tools-search-btn-success"
											icon="el-icon-search"
											@click="initSocketConnect"
											v-if="connectState === false"
											>{{ connectButtonTxt }}</el-button
										>
										<el-button
											class="tools-search-btn-danger"
											icon="el-icon-search"
											@click="disconnect"
											v-else
											>{{ disconnectButtonText }}</el-button
										>
									</template>
								</el-input>
							</el-col>

							<el-col :span="24" class="col button">
								<div>
									<el-input
										:disabled="!connectState"
										type="textarea"
										:placeholder="$t('lang.websocket.websocketContent')"
										v-model.trim="form.textarea"
										rows="4"
									></el-input>
								</div>
								<div class="submit" @click="sendData">
									<a
										class="send"
										:style="[
											{ cursor: connectState ? 'default' : 'not-allowed' },
										]"
										href="javascript:void(0)"
										>{{ $t('lang.websocket.send') }}</a
									>
								</div>
							</el-col>
							<h3 class="title-h3">{{ $t('lang.websocket.deBug') }}</h3>
							<el-col :span="24" class="col result-textarea">
								<div class="msg-txt" style="height: 307px">
									<div
										class="msg-txt-content"
										v-for="(item, index) in consoleList"
										:key="index"
									>
										<div style="color: green">
											{{ item.time }} =>
											<span style="color: red">{{ item.info }}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="13" class="col col-r">
						<el-row>
							<el-col class="but">
								<h3 class="title-h3 inline">
									{{ $t('lang.websocket.msgLog') }}
								</h3>
								<div class="msg-btn">
									<a href="javascript:void(0)" class="msg-a" @click="clear">{{
										$t('lang.websocket.msgClear')
									}}</a>
								</div>
							</el-col>
							<el-col style="margin-top: 10px">
								<!-- <el-input type="textarea" :autosize="{ minRows: 23, maxRows: 25}"></el-input> -->
								<div class="msg-txt">
									<div
										class="msg-txt-content"
										v-for="(msg, index) in messageList"
										:key="index"
									>
										<div style="color: red" v-if="msg.type == 'pull'">
											{{ $t('lang.websocket.sendMsg') }}==>{{ msg.time }}
										</div>
										<div style="color: blue" v-else>
											{{ $t('lang.websocket.receiveMsg') }}==>{{ msg.time }}
										</div>

										<div style="color: green">{{ msg.info }}</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import { get_tools_detail } from '@/api/tools';

let timer;
export default {
	name: 'Websocket',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			value: '',
			result: '',
			btnStateArr: [],
			textareaState: false,
			loading: false,
			socketObj: null,
			connectState: false,
			inputState: false,
			connectButtonTxt: this.$t('lang.websocket.open'),
			disconnectButtonText: this.$t('lang.websocket.break'),
			messageList: [],
			form: {
				wsAddress: 'ws://121.40.165.18:8800',
				pass: '',
				checkPass: '',
				age: '',
				textarea: '',
			},
			consoleList: [],
			h3: '',
		};
	},
	created() {
		this.getToolsDetail();
	},
	methods: {
		getToolsDetail() {
			get_tools_detail({ id: this.$route.query.id }).then((res) => {
				if (res) {
					this.h3 = res.name;
				}
			});
		},
		debounce() {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				this.$message({
					message: this.$t('lang.websocket.soleInfo6'),
					type: 'error',
				});
				this.connectButtonTxt = this.$t('lang.websocket.open');
				timer = undefined;
			}, 1500);
		},
		getColor(type) {
			if (type == 'push') {
				return 'yellow';
			}
			return 'blue';
		},
		clear() {
			this.messageList = [];
		},
		pushConsoleInfo(message) {
			let item = { time: this.getTimeStr(), info: message };
			this.consoleList.unshift(item);
		},
		getTimeStr: function () {
			let myDate = new Date();
			let str =
				myDate.getHours() +
				':' +
				myDate.getMinutes() +
				':' +
				myDate.getSeconds();
			// console.log(str)
			return str;
		},
		initSocketConnect() {
			if (!this.form.wsAddress) {
				this.inputState = true;
				this.$message.error(this.$t('lang.websocket.inputState'));
			} else {
				if (this.connectState === true || this.socketObj !== null) {
					this.pushConsoleInfo(this.$t('lang.websocket.soleInfo1'));
					return;
				}
				try {
					this.connectButtonTxt = this.$t('lang.websocket.now');
					this.pushConsoleInfo(this.$t('lang.websocket.now'));
					this.socketObj = new WebSocket(this.form.wsAddress);
					console.log(this.socketObj);
					this.socketObj.onopen = this.onOpen;
					this.socketObj.onmessage = this.onMessage;
					this.socketObj.onerror = this.onError;
					this.socketObj.onclose = this.onClose;
				} catch (error) {
					this.debounce();
					console.log('error:' + error);
				}
			}
		},
		onOpen() {
			this.connectState = true;
			this.pushConsoleInfo(this.$t('lang.websocket.soleInfo5'));
		},
		onMessage(ev) {
			this.pushMessage(ev.data);
		},
		onClose() {
			this.pushConsoleInfo(this.$t('lang.websocket.soleInfo7'));
			this.connectButtonTxt = this.$t('lang.websocket.open');
			this.disconnectButtonText = this.$t('lang.websocket.break');
			this.connectState = false;
			this.socketObj = null;
		},
		onError(ev) {
			console.log('onError:' + JSON.stringify(ev));
			this.pushConsoleInfo(this.$t('lang.websocket.soleInfo2'));
		},
		disconnect() {
			if (!this.form.wsAddress) {
				this.inputState = true;
				this.$message.error(this.$t('lang.websocket.inputState'));
			} else {
				this.pushConsoleInfo(this.$t('lang.websocket.soleInfo3'));
				this.disconnectButtonText = this.$t('lang.websocket.soleInfo3');
				this.socketObj.close();
			}
		},
		sendData() {
			if (!this.connectState) {
				return;
			} else {
				this.pushConsoleInfo(
					this.$t('lang.websocket.soleInfo4') + this.form.textarea
				);
				this.pushMessage(this.form.textarea, false);
				this.socketObj.send(this.form.textarea);
			}
		},
		pushMessage: function (message, isPull = true) {
			let item = {
				time: this.getTimeStr(),
				info: message,
				type: isPull === true ? 'pull' : 'push',
			};
			this.messageList.unshift(item);
		},
	},
};
</script>

<style lang="scss" scoped>
.md5-tools-page {
	height: 703px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	border-radius: 6px;
	box-shadow: 0px 0px 20px 0px rgba(68, 169, 254, 0.15);
	.md5-tools-box {
		flex: 1;
		padding-top: 30px;
		width: 1208px;
		margin: 0 auto;
	}
	.title {
		width: 100%;
		height: 43px;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		line-height: 36px;
		text-align: center;
	}
	.goback {
		float: right;
		font-size: 16px;
		font-weight: 400;
		color: rgba(68, 169, 254, 1);
		cursor: pointer;
		display: flex;
		align-items: center;

		i {
			font-size: 18px;
			font-stretch: extra-condensed;
		}
		&:hover {
			color: #37bef0;
		}
	}
	.websocket-componet {
		width: 1208px;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 0px 20px 0px rgba(68, 169, 254, 0.15);
		padding: 35px;
		border-radius: 6px;
		.el-tabs__header {
			margin: 0;
		}

		// 搜索框部分
		.col {
			margin-top: 14px;
			width: 569px;
		}
		.hash-span {
			margin: 0 12px;
		}
		::v-deep .el-input-group__append {
			padding: 0;
			border: 0;
		}
		// 服务地址
		::v-deep .el-input-group__prepend {
			height: 40px !important;
			border-radius: 6px 0px 0px 6px;
			background: rgba(242, 242, 242, 1);
			border: 1px solid rgba(229, 229, 229, 1);
			color: #000;
			font-weight: 500;
			background-color: #f5f7fa;
			display: table-cell;
			padding: 0;
		}
		// 放大镜
		::v-deep .el-icon-search {
			font-size: 20px;
		}
		::v-deep .el-button {
			margin: 0;
		}
		.col-r {
			margin-left: 20px;
			margin-top: 15px;
		}
		// 开启连接的按钮
		.tools-search-btn-success {
			color: #fff;
			background: rgba(68, 169, 254, 1);
			border-radius: 0px 6px 6px 0px;
			height: 40px;
			padding-top: 10px;
		}
		::v-deep .el-textarea__inner {
			padding-top: 15px;
			border-radius: 6px;
		}
		// 发送消息
		.msg-txt {
			height: 598px;
			border: 1px solid rgba(229, 229, 229, 1);
			border-radius: 6px;
			overflow: auto;
			width: 100%;
			background-color: #fff;
			line-height: 1.5;
			font-size: 14px;
			padding: 10px;
			.msg-txt-content {
				height: auto;
				margin-bottom: 15px;
				word-break: break-all;
			}
		}
		// 消息记录
		.inline {
			display: inline-block;
		}
		// 断开连接按钮
		.tools-search-btn-danger {
			color: #fff;
			background-color: #f56c6c;
			border-color: #f56c6c;
			border-radius: 0 5px 5px 0;
			border-color: #f56c6c;
			padding: 13px 20px;
		}
		// 发送
		.submit {
			width: 80px;
			height: 36px;
			opacity: 1;
			border-radius: 6px;
			text-align: center;
			padding-top: 9px;
			box-shadow: 0px 0px 10px 0px rgba(79, 94, 106, 0.15);

			background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(240, 248, 255, 1) 100%
			);
			margin: 30px 210px;
			border: 1px solid rgba(255, 255, 255, 1);
			.send {
				color: rgba(22, 148, 250, 1);
			}
		}
		// 消息记录
		.but {
			display: flex;
			justify-content: space-between;
			// 清空消息
			.msg-a {
				line-height: 25px;
				color: rgba(128, 128, 128, 1);
			}
		}

		//textarea 的缩放放大框隐藏
		/deep/ .el-textarea__inner {
			resize: none;
		}
		/deep/ .el-input__inner:focus {
			border-color: #409eff !important;
			outline: 0;
		}
		/deep/ .el-input__inner {
			border: 1px solid rgba(229, 229, 229, 1);
			color: rgba(128, 128, 128, 1);
		}
		.warn-active {
			/deep/ .el-textarea__inner {
				border: 1px solid #f56c6c;
				font-family: '';
			}
		}
		.warn-active-input {
			/deep/ .el-input__inner {
				border: 1px solid #f56c6c;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
